<template>
  <v-container>
    <v-progress-linear
      v-if="loadingGroups || loadingUsers || loadingTemplates"
      indeterminate/>
    <v-form>
      <v-row>
        <v-col>
          <v-select
            v-model="form.recipientType"
            :items="recipientTypes"
            class="rounded-0"
            dense
            label="Typ"
            outlined>
          </v-select>
          <v-select
            v-if="form.recipientType === 'manual_group'"
            label="Zvolte příjemce"
            outlined
            v-model="form.userIds"
            :items="users"
            clearable
            item-text="email"
            item-value="id"
            dense
            multiple
            class="rounded-0">
          </v-select>
          <v-select
            v-else-if="form.recipientType === 'emailing_group'"
            v-model="form.emailingGroupId"
            :items="emailingGroups"
            class="rounded-0"
            clearable
            dense
            item-text="name"
            item-value="id"
            label="Zvolte skupinu"
            outlined>
          </v-select>
        </v-col>
        <v-col>
          <v-text-field
            label="Předmět emailu"
            dense
            outlined
            class="rounded-0"
            v-model="form.subject"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-select
            label="Šablona"
            v-model="selectedTemplate"
            outlined
            :items="emailingTemplates"
            item-text="name"
            return-object
            dense
            class="rounded-0">
          </v-select>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col>
        <EmailingTemplateView
          v-if="selectedTemplate"
          :template="selectedTemplate"
          @send-mails="send"
          :loading="sendingMails"
          @template-saved="getEmailingTemplates"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EmailingTemplateView from '@/views/admin/EmailingTemplateView'
export default {
  name: 'Emailing',
  components: { EmailingTemplateView },
  data () {
    return {
      recipientTypes: [
        {
          text: 'Zákazníci',
          value: 'users'
        },
        {
          text: 'Hostitelé',
          value: 'hosts'
        },
        // TODO: podle zeme
        {
          text: 'Skupina uživatelů - manuálně',
          value: 'manual_group'
        },
        {
          text: 'Skupina uživatelů - předdefinovaná',
          value: 'emailing_group'
        }
      ],
      loadingGroups: false,
      loadingTemplates: false,
      loadingUsers: false,
      emailingGroups: [],
      emailingTemplates: [],
      users: [],
      selectedTemplate: null,
      form: {
        userIds: [],
        recipientType: null,
        subject: null,
        html: null,
        emailingGroupId: null
      },
      sendingMails: false
    }
  },
  methods: {
    send ({ html }) {
      this.sendingMails = true
      this.form.html = html
      this.$http.post('/admin/emailing/send', this.form)
        .then(res => {
          this.$store.dispatch('alerts/setAlerts', [{
            message: 'E-maily odeslány',
            type: 'success'
          }])
        })
        .finally(() => {
          this.sendingMails = false
        })
    },
    getUsers () {
      this.loadingUsers = true
      this.$http.get('/admin/users/all')
        .then(res => {
          this.users = res.data
          this.loadingUsers = false
        })
    },
    getEmailingGroups () {
      this.loadingGroups = true
      this.$http.get('/admin/emailing-groups')
        .then(res => {
          this.emailingGroups = res.data
          this.loadingGroups = false
        })
    },
    getEmailingTemplates () {
      this.loadingTemplates = true
      this.$http.get('/admin/emailing-templates')
        .then(res => {
          this.emailingTemplates = res.data
          this.emailingTemplates.push({
            id: null,
            name: 'Prázdná šablona',
            // prazdna sablona
            // !!! proste to akceptovat, nehrabat !!!
            json: '{"counters":{"u_column":1,"u_row":1},"body":{"rows":[{"cells":[1],"columns":[{"contents":[],"values":{"backgroundColor":"","padding":"0px","border":{},"_meta":{"htmlID":"u_column_1","htmlClassNames":"u_column"}}}],"values":{"displayCondition":null,"columns":false,"backgroundColor":"","columnsBackgroundColor":"","backgroundImage":{"url":"","fullWidth":true,"repeat":false,"center":true,"cover":false},"padding":"0px","hideDesktop":false,"_meta":{"htmlID":"u_row_1","htmlClassNames":"u_row"},"selectable":true,"draggable":true,"duplicatable":true,"deletable":true,"hideable":true}}],"values":{"textColor":"#000000","backgroundColor":"#e7e7e7","backgroundImage":{"url":"","fullWidth":true,"repeat":false,"center":true,"cover":false},"contentWidth":"500px","contentAlign":"center","fontFamily":{"label":"Arial","value":"arial,helvetica,sans-serif"},"preheaderText":"","linkStyle":{"body":true,"linkColor":"#0000ee","linkHoverColor":"#0000ee","linkUnderline":true,"linkHoverUnderline":true},"_meta":{"htmlID":"u_body","htmlClassNames":"u_body"}}},"schemaVersion":6}'
          })
          this.loadingTemplates = false
        })
    }
  },
  created () {
    this.getUsers()
    this.getEmailingGroups()
    this.getEmailingTemplates()
  }
}
</script>

<style scoped>

</style>
