<template>
  <v-container>
    <v-form v-model="formValid">
      <div id="bar">
        <h1>Emailing Editor </h1>
        <div class="d-flex">
          <v-text-field
            v-model.trim="form.name"
            :rules="[validation.required, validation.maxLength(255)]"
            max="255"
            class="rounded-0"
            dense
            ref="templateNameInput"
            label="Název šablony"
            outlined
            style="max-width: 550px"
          >
          </v-text-field>
          <v-btn
            :disabled="!formValid || savingTemplate" :loading="loading"
            color="primary ml-5"
            @click="saveDesign"
            class="rounded-0"
          >Uložit šablonu
          </v-btn>
          <v-btn
            :disabled="savingTemplate"
            :loading="loading"
            class="rounded-0"
            color="red ml-5"
            dark
            v-on:click="sendMail"
          >Odeslat e-mail
          </v-btn>
        </div>
      </div>
    </v-form>

    <EmailEditor
      ref="emailEditor"
      v-model="form.json"
      :appearance="appearance"
      :locale="locale"
      :min-height="minHeight"
      :options="options"
      :project-id="projectId"
      :tools="tools"
      v-on:load="editorLoaded">
    </EmailEditor>
  </v-container>
</template>

<script>
import { EmailEditor } from 'vue-email-editor/src/components'
import { mapActions } from 'vuex'

export default {
  name: 'EmailingTemplateView',
  components: {
    EmailEditor
  },
  props: {
    template: {
      required: true,
      type: Object
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      // editor
      minHeight: '1000px',
      locale: 'cs',
      projectId: 0, // replace with your project id
      tools: {
        // disable image tool
        image: {
          enabled: false
        }
      },
      options: {},
      appearance: {
        theme: 'light',
        panels: {
          tools: {
            dock: 'right'
          }
        }
      },
      // data
      formValid: true,
      form: {
        name: null
      },
      savingTemplate: false
    }
  },
  methods: {
    editorLoaded () {
      this.$refs.emailEditor.editor.loadDesign(JSON.parse(this.template.json))
      this.form.name = this.template.name
    },
    saveDesign () {
      this.savingTemplate = true
      this.$refs.emailEditor.editor.saveDesign((design) => {
        // console.log(JSON.stringify(design))
        const options = {
          method: this.template.id ? 'PUT' : 'POST',
          url: this.template.id ? `/admin/emailing-templates/${this.template.id}` : '/admin/emailing-templates',
          data: {
            name: this.form.name,
            json: JSON.stringify(design)
          }
        }
        this.$http(options)
          .then(res => {
            this.$refs.emailEditor.editor.loadDesign(JSON.parse(res.data.json))
            this.$emit('template-saved')
            this.setAlerts([{
              message: 'Šablona uložena',
              type: 'success'
            }])
          })
          .finally(() => {
            this.savingTemplate = false
          })
      })
    },
    sendMail () {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        this.$emit('send-mails', {
          html: data.html
        })
      })
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  watch: {
    template: {
      deep: true,
      handler (val) {
        // this.$refs.templateNameInput.reset()
        this.form.name = val.name
        this.$refs.emailEditor.editor.loadDesign(JSON.parse(val.json))
      }
    }
  }
}
</script>

<style scoped>
>>> .NKxbi .blockbuilder-branding {
  display: none !important;
}

>>> iframe {
  min-height: inherit !important;
}
</style>
